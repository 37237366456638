import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import MeetingVideoImg from './../assets/img/teams_icon_2.svg';
import MeetingGroupImg from './../assets/img/person-filled.svg';
import MeetingZoomImg from './../assets/img/zoom_icon.png';

export function calculateAge(dob: string): number {
  const today = new Date();
  const birthDate = new Date(dob);

  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDiff = today.getMonth() - birthDate.getMonth();

  // If birth date month is greater than current month or
  // if birth date month is equal to current month but birth date day is greater than current day,
  // then decrement age by 1
  if (
    monthDiff < 0 ||
    (monthDiff === 0 && today.getDate() < birthDate.getDate())
  ) {
    age--;
  }

  return age;
}

export function formatDateTime(dateTimeString: string): {
  date: string;
  time: string;
} {
  if (!dateTimeString) return { date: "", time: "" };
  const date = new Date(dateTimeString);
  const dateOptions: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  const timeOptions: Intl.DateTimeFormatOptions = {
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
  };

  const formattedDate = date.toLocaleDateString("en-US", dateOptions);
  const formattedTime = date.toLocaleTimeString("en-US", timeOptions);

  return { date: formattedDate, time: formattedTime };
}

export function arrayBufferToBase64(buffer) {
  var binary = "";
  var bytes = new Uint8Array(buffer);
  var len = bytes.byteLength;
  for (var i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
}
export const isExtension = () => false;
  // window.location.protocol === "chrome-extension:";
export const isSmallDevice = () =>
  window.matchMedia("(max-width: 600px)").matches;
export const isMobile = () => isSmallDevice() && !isExtension();

export const injectMicrophonePermissionIframe = () => {
  // const iframe = document.createElement("iframe");
  // iframe.setAttribute("hidden", "hidden");
  // iframe.setAttribute("id", "permissionsIFrame");
  // iframe.setAttribute("allow", "microphone");
  // iframe.src = chrome.runtime.getURL("permission/index.html");
  // document.body.appendChild(iframe);

  navigator.mediaDevices.getUserMedia({ audio: true }).catch((err) => {
    console.log(err);

    chrome.tabs.create({
      url: chrome.runtime.getURL("permission/index.html"),
      active: true,
    });
  });
};

export const validateEmail = (email: string): boolean => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

export const validateOtp = (otp: string): boolean => {
  const otpRegex = /^\d{1,6}$/;
  return otpRegex.test(otp);
};

export const getMeetingHtmlContent = ({
  organizationDetails,
  meetingDetails,
  notesHtml,
  summaryHtml,
}) => {
  const { date, time } = formatDateTime(meetingDetails.start_time);
  const firmParticipants = meetingDetails.firm_side_participants
    .map(
      (i, index) =>
        (index === 0 ? "" : ", ") + `${i.name} (${i.meeting_role_display})`
    )
    .join("");
  const clients = meetingDetails.client_side_participants
    .map((i) => `${i.name} (client)`)
    .join(", ");

  return `
  <div style="font-family: Arial, sans-serif; line-height: 1.6; color: #333;">
    ${organizationDetails?.organization?.header_html ?
       organizationDetails.organization.header_html : ''
    }
    <h2 style="color: #5D00E2;">${meetingDetails.title}</h2>
    <p style="font-size: 14px; margin-bottom: 20px;">
      <strong>Date:</strong> ${date} | ${time}
    </p>
    <p style="font-size: 14px; margin-bottom: 20px;">
      <strong>Attendees:</strong> ${firmParticipants}${
    clients ? ", " + clients : ""
  }
    </p>
    ${
      summaryHtml
        ? `
    <div style="margin-bottom: 20px;">
      <h4 style="color: #5D00E2; margin-bottom: 10px;">Summary</h4>
      <div style="padding: 10px; background-color: #F9F9F9; border-left: 4px solid #5D00E2;">
        ${summaryHtml}
      </div>
    </div>
    `
        : ""
    }
    ${
      notesHtml
        ? `
    <div style="margin-bottom: 20px;">
      <h4 style="color: #5D00E2; margin-bottom: 10px;">Notes</h4>
      <div style="padding: 10px; background-color: #F9F9F9; border-left: 4px solid #5D00E2;">
        ${notesHtml}
      </div>
    </div>
    `
        : ""
    }
    ${organizationDetails?.organization?.footer_html ? organizationDetails.organization.footer_html : ''}
  </div>
  `;
};

export function compareVersions(v1, v2) {
  const v1Parts = v1.split(".").map(Number);
  const v2Parts = v2.split(".").map(Number);

  for (let i = 0; i < 3; i++) {
    if (v1Parts[i] < v2Parts[i]) {
      return -1; // v1 is less than v2
    }
    if (v1Parts[i] > v2Parts[i]) {
      return 1; // v1 is greater than v2
    }
  }

  return 0; // v1 is equal to v2
}

export const hardReload = () => {
  // Store the current URL without any parameters
  const originalURL = window.location.href.split("?")[0];

  // Append a timestamp to the URL to force a reload
  const newURL = `${originalURL}?timestamp=${new Date().getTime()}`;

  // Change the URL to the one with the timestamp to bypass the cache
  window.location.href = newURL;

  // Use replaceState to clean the URL immediately after reload
  window.history.replaceState(null, "", originalURL);
};

export function generateUUID() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export function secondsToMinutes(seconds: number): string {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
}

export const compose = (...classes) => {
  if (!classes || !classes.length) {
    return '';
  }
  return classes.filter(Boolean).join(' ');
};

const getOrdinal = function (number, appendWithNumber) {
  appendWithNumber = (typeof appendWithNumber !== 'undefined') ? appendWithNumber : true;
  const s = ['th', 'st', 'nd', 'rd'],
      v = number % 100;
  return (appendWithNumber ? number : '') + (s[(v - 20) % 10] || s[v] || s[0]);
};


const digitPercisionString = function (digit, point) {
  digit = digit.toString();
  if (digit.length < point) {
      let pre = '';
      for (let i = digit.length; i < point; i++) {
          pre += '0';
      }
      digit = pre + digit;
  } else if (digit.length > point) {
      digit = parseFloat(digit).toPrecision(point);
  }
  return digit;
}

export const MONTH_NAMES = [
  { "short": "Jan", "long": "January" },
  { "short": "Feb", "long": "February" },
  { "short": "Mar", "long": "March" },
  { "short": "Apr", "long": "April" },
  { "short": "May", "long": "May" },
  { "short": "Jun", "long": "June" },
  { "short": "Jul", "long": "July" },
  { "short": "Aug", "long": "August" },
  { "short": "Sep", "long": "September" },
  { "short": "Oct", "long": "October" },
  { "short": "Nov", "long": "November" },
  { "short": "Dec", "long": "December" }
]

export const WEEK_DAYS = {
  shortForm: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
  longForm: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
};

export const parseFormatDate = function (dateTime) {
  dateTime = parseInt(dateTime, 10);
  if (!dateTime || dateTime < 0) {
      return;
  }
  let obj = {};
  //DD/MM/YYYY HH:min TT
  const date = new Date(dateTime);
  obj['dd'] = date.getDate();
  obj['dayLong'] = WEEK_DAYS.longForm[date.getDay()];
  obj['dayShort'] = WEEK_DAYS.shortForm[date.getDay()];
  obj['ord'] = getOrdinal(date.getDate(), false);
  obj['MM'] = MONTH_NAMES[date.getMonth()].long;
  obj['mmm'] = MONTH_NAMES[date.getMonth()].short;
  obj['mm'] = digitPercisionString(date.getMonth() + 1, 2);
  obj['yyyy'] = date.getFullYear();
  obj['yy'] = date.getFullYear().toString().substr(2);
  obj['hrs'] = date.getHours();
  obj['hrh'] = (date.getHours() === 12 ? 12 : date.getHours() % 12);
  obj['min'] = digitPercisionString(date.getMinutes(), 2);
  obj['sec'] = digitPercisionString(date.getSeconds(), 2);
  obj['mdnC'] = date.getHours() >= 12 ? 'PM' : 'AM';
  obj['mdn'] = date.getHours() >= 12 ? 'pm' : 'am';
  return obj;
};

export const timeRemainingAsObject = (milliseconds, skipDays) => {
  milliseconds = parseInt(milliseconds);
  var seconds = parseInt((milliseconds / 1000) % 60);
  var minutes = parseInt((milliseconds / (1000 * 60)) % 60);
  var hours = 0;
  var days = 0;
  if (skipDays) {
    hours = parseInt(milliseconds / (1000 * 60 * 60));
  } else {
    hours = parseInt((milliseconds / (1000 * 60 * 60)) % 24);
    days = parseInt(milliseconds / (1000 * 60 * 60) / 24);
  }

  let timeRemainingObject = {};
  timeRemainingObject['days'] = days;
  timeRemainingObject['hours'] = hours;
  timeRemainingObject['minutes'] = digitPercisionString(minutes, 2);
  timeRemainingObject['seconds'] = digitPercisionString(seconds, 2);

  return timeRemainingObject;
};

export const getMeetingImgType = (meeting_location) => {
  switch (meeting_location) {
      case "Microsoft Teams": return MeetingVideoImg;
      case "Google Meet": return MeetingVideoImg;
      case "Zoom": return MeetingZoomImg;
      default: return MeetingGroupImg;
  }
}