import { createAsyncThunk } from "@reduxjs/toolkit";
import { get, post } from "../../api";
import { ROUTES } from "../../api/config";

export const fetchUserProfile = createAsyncThunk(
  "app/fetchUserProfile",
  async () => {
    const data = await get(ROUTES.USER_PROFILE);
    return data;
  }
);

export const fetchClientList = createAsyncThunk(
  "app/fetchClientList",
  async () => {
    const data = await get(ROUTES.CLIENT_LIST);
    return data;
  }
);

export const fetchTeamMemberList = createAsyncThunk(
  "app/fetchTeamMemberList",
  async () => {
    const data = await get(ROUTES.TEAM_MEMBER_LIST);
    return data;
  }
);

export const fetchClientDetails = createAsyncThunk(
  "app/fetchClientDetails",
  async (clientId: string) => {
    const data = await get(ROUTES.CLIENT_DETAILS(clientId));
    return data;
  }
);

export const fetchMeetingList = createAsyncThunk(
  "app/fetchMeetingList",
  async (offset: number) => {
    const data = await get(ROUTES.MEETING_LIST(offset));
    return { data, offset };
  }
);

export const fetchMeetingListV2 = createAsyncThunk(
  "app/fetchMeetingListV2",
  async (payload: {user_id: string,cursor_time?:string,client_ids?:Array<number>,filter_month?:string, filter_year?:string, offset: number}) => {
    const {user_id,cursor_time,client_ids,filter_month, filter_year, offset} = payload;
    const data = await get(ROUTES.MEETING_LIST_V2(user_id,cursor_time,client_ids,filter_month, filter_year));
    return { data, offset };
  }
);

export const fetchMeetingListV3 = createAsyncThunk(
  "app/fetchMeetingListV3",
  async (payload: {
    client_ids?: Array<number>;
    team_member_ids?: Array<number>;
    shared_type: string;
    section: string;
    offset: number;
    start_date?: number;
    end_date?: number;
  }) => {
    const { client_ids, offset, team_member_ids, shared_type, section, start_date, end_date } =
      payload;
    const data = await get(
      ROUTES.MEETING_LIST_V3(
        start_date,
        end_date,
        client_ids,
        team_member_ids,
        offset,
        shared_type,
        section
      )
    );
    return { data, offset };
  }
);

export const fetchSharedMeetingList = createAsyncThunk(
  "app/fetchSharedMeetingList",
  async (payload: {user_id: string,client_ids?:Array<number>,team_member_ids?:Array<number>,filter_month?:string, filter_year?:string, offset: number}) => {
    const {user_id,client_ids,filter_month, filter_year, offset, team_member_ids} = payload;
    const data = await get(ROUTES.SHARED_MEETING_LIST(user_id,offset,client_ids,filter_month, filter_year,team_member_ids));
    return { data, offset };
  }
);

export const fetchIntegrationsList = createAsyncThunk(
  "app/fetchIntegrationsList",
  async () => {
    const data = await get(ROUTES.GET_INTEGRATION_LIST);
    return { data };
  }
);

export const fetchClientMeetingList = createAsyncThunk(
  "app/fetchClientMeetingList",
  async (clientId: string) => {
    const data = await get(ROUTES.CLIENT_MEETING_LIST(clientId));
    return data;
  }
);

export const fetchMeetingDetails = createAsyncThunk(
  "app/fetchMeetingDetails",
  async ({ meetingId }: { meetingId: string; silentRefresh?: boolean }) => {
    const data = await get(ROUTES.MEETING_DETAILS(meetingId));
    return data;
  }
);

export const updateMeetingDetails = createAsyncThunk(
  "app/updateMeetingDetails",
  async (PL:{meetingId:string, section?:string}, { getState }: any) => {
    const state = getState();
    let payload = { ...state.app.meetingDetails.data };
    delete payload.mili_status;

    const data = await post(ROUTES.MEETING_UPDATE(PL.meetingId), payload);
    return data;
  }
);

export const createMeeting = createAsyncThunk("app/createMeeting", async () => {
  const data = await post(ROUTES.MEETING_CREATE, {});
  return data;
});

export const fetchContactList = createAsyncThunk(
  "app/fetchContactList",
  async () => {
    const data = await get(ROUTES.CONTACT_LIST);
    return data;
  }
);

export const fetchAdvisorsList = createAsyncThunk(
  "app/fetchAdvisorsList",
  async () => {
    const data = await get(ROUTES.ADVISORS_LIST);
    return data;
  }
);

export const fetchMeetingTypeList = createAsyncThunk(
  "app/fetchMeetingTypeList",
  async () => {
    const data = await get(ROUTES.MEETING_TYPE_LIST);
    return data;
  }
);

export const fetchEMoneyDetails = createAsyncThunk(
  "app/fetchEMoneyDetails",
  async ({ meetingId, payload }: { meetingId: string; payload: any }) => {
    const data = await post(ROUTES.GET_EMONEY_DETAILS(meetingId), payload);
    return data;
  }
);

export const fetchMeetingSharedList = createAsyncThunk(
  "app/fetchMeetingSharedList",
  async ({
    meetingId,
    silentRefresh,
  }: {
    meetingId: string;
    silentRefresh: boolean;
  }) => {
    const data = await get(ROUTES.GET_SHARED_LIST(meetingId));
    return data;
  }
);

export const fetchMeetingShareableList = createAsyncThunk(
  "app/fetchMeetingShareableList",
  async ({ meetingId }: { meetingId: string }) => {
    const data = await get(ROUTES.GET_SHAREABLE_LIST(meetingId));
    return data;
  }
);

export const updateMeetingShareList = createAsyncThunk(
  "app/updateMeetingShareList",
  async ({ meetingId, payload }: { meetingId: string; payload: any }) => {
    const data = await post(
      ROUTES.UPDATE_MEETING_SHARE_LIST(meetingId),
      payload
    );
    return data;
  }
);

export const addNoteToSalesForce = createAsyncThunk("app/addNoteToSalesForce", async ({ meetingId, payload }: { meetingId: string; payload: any }) => {
  const data = await post(ROUTES.ADD_NOTE_TO_SALESFORCE(meetingId), payload);
  return data;
});

export const addActionItemsToSalesForce = createAsyncThunk("app/addActionItemsToSalesForce", async ({ meetingId, payload }: { meetingId: string; payload: any }) => {
  const data = await post(ROUTES.ADD_ACTION_ITEMS_TO_SALESFORCE(meetingId), payload);
  return data;
});

export const fetchOrganizationDetails = createAsyncThunk("app/fetchOrganizationDetails", async () => {
  const data = await get(ROUTES.ORGANIZATION_DETAILS);
  return data;
});

export const finalizeMeetingNotes = createAsyncThunk("app/finalizeMeetingNotes", async ({ meetingId }: { meetingId: string }) => {
  const data = await post(ROUTES.FINALIZE_MEETING_NOTES(meetingId));
  return data;
});
