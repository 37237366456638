import { createContext, useContext, useState, useEffect, useRef, useCallback } from "react";
import { ROUTES } from "../api/config";
import { arrayBufferToBase64 } from "../utils/Util";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../redux/store";
import { toastEmitter } from "../components/toast/toastManager";
import Mixpanel, { EventNames } from "../utils/analytics/mixpanel";
import useNetworkStatus, { NetworkStatus } from "../hooks/useNetworkStatus";
import useWakeLock from "../hooks/useWakeLock";
import useAudio, { AUDIO_PATH } from "../hooks/useAudio";
import { isEqual } from 'lodash'; // Make sure to install and import lodash

// To set the initial sample rate for audioContext
const SAMPLE_RATES = [16000, 22050, 32000, 44100, 48000];
var SAMPLE_RATE = 16000;



type MiliStates =
  | "NOT_STARTED"
  | "IN_PROGRESS"
  | "RESUME"
  | "FINALIZING_NOTES"
  | "GENERATING_SUMMARY"
  | "GENERATING_FOLLOW_UP"
  | "GENERATING_ACTION_ITEMS"
  | "COMPLETED"
  | "RECONNECTING"
  | "STOPPED"
  | null;

type HandleUpdateMeetingMaxTiming = ({
  data,
  action,
}: {
  data: {
    type: "TIMER";
    remainingTime: number;
  };
  action: "UPDATE" | "RESET";
}) => void;

type MaxTime = number | null;

const SocketContext = createContext<any>({});

export const useSocket = () => useContext(SocketContext);

function sleep(ms: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

const MAX_RECONNECT_DEPTH = 2;

// Add these constants near the top of the file
const LOCAL_STORAGE_MIC_KEY = 'selectedMicDeviceId';
const LOCAL_STORAGE_SPEAKER_KEY = 'selectedSpeakerDeviceId';

export const SocketProvider = ({ children }) => {
  const isConnectedRef = useRef<boolean>(false);
  const isSocketReadyToSendData = useRef<boolean>(false);
  const [activeMeetingId, setActiveMeetingId] = useState(null);
  const activeMeetingIdRef = useRef(null);
  const socketRef = useRef<WebSocket | null>(null);
  const [miliState, setMiliState] = useState<MiliStates>(null);
  const [meetingMaxTime, setMeetingMaxTime] = useState<MaxTime>(null);
  const isRecordingRef = useRef(false);
  const token = useSelector((state: RootState) => state.app.token);
  const dispatch = useDispatch<AppDispatch>();
  const [meetingExtended, setMeetingExtended] = useState<boolean>(false);
  const [audioInputDevices, setAudioInputDevices] = useState([
  ]);
  const [audioOutputDevices, setAudioOutputDevices] = useState([

  ]);

  const previousAudioInputDevicesRef = useRef([]);
  const previousAudioOutputDevicesRef = useRef([]);
  const [selectedMicDeviceId, setSelectedMicDeviceId] = useState<string | null>(null);
  const selectedMicDeviceIdRef = useRef<string | null>(null);
  const selectedSpeakerDeviceIdRef = useRef<string | null>(null);
  const [selectedSpeakerDeviceId, setSelectedSpeakerDeviceId] = useState<string | null>(null);
  const [showMicrophoneSelector, setShowMicrophoneSelector] = useState(false);
  const userHasInteractedRef = useRef(false);

  const audioContextRef = useRef(null)
  const scriptProcessorRef = useRef(null)
  const streamRef = useRef<MediaStream | null>(null);
  const networkStatus: NetworkStatus = useNetworkStatus();
  const audioChunksRef = useRef([]);
  const networkStatusRef = useRef(null);
  const networkTimestamp = useRef(null);
  const miliStateRef = useRef<MiliStates>(null);
  const { requestWakeLock, releaseWakeLock } = useWakeLock();
  const { play } = useAudio();

  const reconnectAttemptsRef = useRef(0);
  const maxReconnectAttempts = 20;
  const initialReconnectDelay = 1000 * 15;

  // New refs for packet sequencing and acknowledgment
  const packetSequenceNumberRef = useRef(0);
  const sentPacketsRef = useRef<{ [key: number]: string }>({});
  const lastAckIdRef = useRef(-1);
  const maxPacketBufferSize = 300;
  const audioMetadataRef = useRef(null);
  const connectTimeoutRef = useRef(null);


  // Add a ref to track if audio context has been initialized
  const audioContextInitialized = useRef(false);

  const initializeAudioContext = () => {
    for (const rate of SAMPLE_RATES) {
      try {
        // @ts-ignore
        const audioContext = new (window.AudioContext || window.webkitAudioContext)(
          { sampleRate: rate }
        );
        if (audioContext.sampleRate === rate) {
          SAMPLE_RATE = rate;
          console.log("SAMPLE_RATE", SAMPLE_RATE)
          audioContext.close();
          break;
        }
        audioContext.close();
      } catch (e) {
        // Sample rate not supported
      }
    }
  };


  const handleUserInteraction = () => {
    if (!audioContextInitialized.current) {
      audioContextInitialized.current = true;
      initializeAudioContext();
      // Remove the event listeners after initialization
      window.removeEventListener('mousedown', handleUserInteraction);
      window.removeEventListener('keydown', handleUserInteraction);
      window.removeEventListener('touchstart', handleUserInteraction);
    }
  };

  useEffect(() => {
    // Add event listeners for user interaction
    window.addEventListener('mousedown', handleUserInteraction);
    window.addEventListener('keydown', handleUserInteraction);
    window.addEventListener('touchstart', handleUserInteraction);

    // Cleanup function
    return () => {
      window.removeEventListener('mousedown', handleUserInteraction);
      window.removeEventListener('keydown', handleUserInteraction);
      window.removeEventListener('touchstart', handleUserInteraction);
    };
  }, []);


  const handleDeviceList = useCallback((devices) => {
    if (!devices) {
      return;
    }

    // Separate capture and render devices
    const audioInputDevicesRef = devices.filter(device => device.Type === 'capture');
    const audioOutputDevicesRef = devices.filter(device => device.Type === 'render');

    const sortDevices = (deviceList) => {
      if (deviceList.length <= 1) return deviceList;
      return deviceList.sort((a, b) => {
        if (a.IsDefault && !b.IsDefault) return -1;
        if (!a.IsDefault && b.IsDefault) return 1;
        return 0;
      });
    };

    sortDevices(audioInputDevicesRef);
    sortDevices(audioOutputDevicesRef);

    setAudioInputDevices(audioInputDevicesRef);
    setAudioOutputDevices(audioOutputDevicesRef);

    console.log('Available audio input devices:', audioInputDevicesRef);
    console.log('Available audio output devices:', audioOutputDevicesRef);

    console.log("Current selectedMicDeviceId:", selectedMicDeviceIdRef.current);
    console.log("Current selectedSpeakerDeviceId:", selectedSpeakerDeviceIdRef.current);


    console.log("Current Selected Mic Device Id :", selectedMicDeviceIdRef.current);
    console.log("Current Selected Speaker Device Id :", selectedSpeakerDeviceIdRef.current);
    if (audioInputDevicesRef.length > 0) {
      const savedMicId = localStorage.getItem(LOCAL_STORAGE_MIC_KEY);
      if (!selectedMicDeviceIdRef.current) {
        // Try to use saved device first, fall back to default
        const savedDevice = audioInputDevicesRef.find(device => device.Id === savedMicId);
        if (savedDevice) {
          console.log("Using saved mic device:", savedDevice.Id, savedDevice.Name);
          setSelectedMicDeviceId(savedDevice.Id);
        } else {
          console.log("No saved mic device found, setting to default", audioInputDevicesRef[0].Id);
          setSelectedMicDeviceId(audioInputDevicesRef[0].Id);
        }
      }
      else if (selectedMicDeviceIdRef.current && !audioInputDevicesRef.some(device => device.Id === selectedMicDeviceIdRef.current)) {
        // Current Device is Removed
        console.log("Current mic device is removed, setting to default", audioInputDevicesRef[0].Id, audioInputDevicesRef[0].Name);
        setSelectedMicDeviceId(audioInputDevicesRef[0].Id);
      }
      else if (!socketRef.current && !userHasInteractedRef.current) {
        // New Device Added
        // Find the new device by taking diff previousAudioInputDevicesRef and audioInputDevicesRef
        const newDevice = audioInputDevicesRef.find(device => !previousAudioInputDevicesRef.current.some(prevDevice => prevDevice.Id === device.Id));
        if (newDevice) {
          console.log("New mic device is added, setting to default", newDevice.Id, newDevice.Name);
          setSelectedMicDeviceId(newDevice.Id);
        }
      }
    }


    if (audioOutputDevicesRef.length > 0) {
      const savedSpeakerId = localStorage.getItem(LOCAL_STORAGE_SPEAKER_KEY);
      if (!selectedSpeakerDeviceIdRef.current) {
        // Try to use saved device first, fall back to default
        const savedDevice = audioOutputDevicesRef.find(device => device.Id === savedSpeakerId);
        if (savedDevice) {
          console.log("Using saved speaker device:", savedDevice.Id, savedDevice.Name);
          setSelectedSpeakerDeviceId(savedDevice.Id);
        } else {
          console.log("No saved speaker device found, setting to default", audioOutputDevicesRef[0].Id);
          setSelectedSpeakerDeviceId(audioOutputDevicesRef[0].Id);
        }
      }
      else if (selectedSpeakerDeviceIdRef.current && !audioOutputDevicesRef.some(device => device.Id === selectedSpeakerDeviceIdRef.current)) {
        // Current Device is Removed
        console.log("Current speaker device is removed, setting to default", audioOutputDevicesRef[0].Id, audioOutputDevicesRef[0].Name);
        setSelectedSpeakerDeviceId(audioOutputDevicesRef[0].Id);
      }
      else if (!socketRef.current && !userHasInteractedRef.current) {
        // New Device is Added
        const newDevice = audioOutputDevicesRef.find(device => !previousAudioOutputDevicesRef.current.some(prevDevice => prevDevice.Id === device.Id));
        if (newDevice) {
          console.log("New speaker device is added, setting to default", newDevice.Id, newDevice.Name);
          setSelectedSpeakerDeviceId(newDevice.Id);
        }
      }
    }

  }, []);

  useEffect(() => {
    if (!isEqual(previousAudioInputDevicesRef.current, audioInputDevices) || !isEqual(previousAudioOutputDevicesRef.current, audioOutputDevices)) {
      setShowMicrophoneSelector(true);
      previousAudioInputDevicesRef.current = audioInputDevices;
      previousAudioOutputDevicesRef.current = audioOutputDevices;


      Mixpanel.track(EventNames.LIST_AUDIO_DEVICES_ON_CHANGE, {
        "Meeting ID": activeMeetingIdRef.current,
        "Input Devices": audioInputDevices.map(device => ({
          Id: device.Id,
          Name: device.Name,
          IsDefault: device.IsDefault,
          Type: device.Type
        })),
        "Output Devices": audioOutputDevices.map(device => ({
          Id: device.Id,
          Name: device.Name,
          IsDefault: device.IsDefault,
          Type: device.Type
        })),
        "Total Input Devices": audioInputDevices.length,
        "Total Output Devices": audioOutputDevices.length
      });
    }
  }, [audioInputDevices, audioOutputDevices]);


  // useEffect(() => {
  //   if (showMicrophoneSelector) {
  //     if(window.chrome && window.chrome.webview) {
  //       window.chrome.webview.postMessage({
  //         type: 'refresh'
  //       });
  //     }
  //   }
  // }, [showMicrophoneSelector]);

  // useEffect(() => {
  //   if(window.chrome && window.chrome.webview) {
  //     window.chrome.webview.postMessage({
  //       type: 'refresh'
  //     });
  //   }
  // }, []);

  const detectInitialAudioDevices = async () => {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const audioDevices = devices.filter(device => device.kind.startsWith('audio'));

      const inputDevices = audioDevices.filter(device => device.kind === 'audioinput');
      const outputDevices = audioDevices.filter(device => device.kind === 'audiooutput');

      Mixpanel.track(EventNames.INITIAL_AUDIO_DEVICES_DETECTED, {
        "Total Input Devices": inputDevices.length,
        "Total Output Devices": outputDevices.length,
        "Input Devices": inputDevices.map(device => ({
          deviceId: device.deviceId,
          label: device.label || 'Unnamed Device',
          default: device.deviceId === 'default'
        })),
        "Output Devices": outputDevices.map(device => ({
          deviceId: device.deviceId,
          label: device.label || 'Unnamed Device',
          default: device.deviceId === 'default'
        }))
      });

      console.log('Initial audio devices detected:', {
        inputs: inputDevices,
        outputs: outputDevices
      });
    } catch (error) {
      console.error('Error detecting initial audio devices:', error);
      Mixpanel.track(EventNames.INITIAL_AUDIO_DEVICES_ERROR, {
        "Error": error.message
      });
    }
  };


  const sendAppOpenEvent = () => {
    Mixpanel.track(EventNames.APP_LOAD, {});
  }

  // Add this useEffect to run once when component mounts
  useEffect(() => {
    detectInitialAudioDevices();
    sendAppOpenEvent();
  }, []);

  const handleSystemDefaultAudioDeviceChange = (device) => {
    if (device.Type === "capture") {
      Mixpanel.track(EventNames.SYSTEM_DEFAULT_AUDIO_DEVICE_CHANGE, {
        "Meeting ID": activeMeetingIdRef.current,
        "Device Type": "Mic",
        "Device ID": device.Id,
        "Device Name": device.Name,
      });
    }
    if (device.Type === "render") {
      Mixpanel.track(EventNames.SYSTEM_DEFAULT_AUDIO_DEVICE_CHANGE, {
        "Meeting ID": activeMeetingIdRef.current,
        "Device Type": "Speaker",
        "Device ID": device.Id,
        "Device Name": device.Name,
      });
    }
    console.log("System default audio device changed", device.Name);
  }


  useEffect(() => {
    miliStateRef.current = miliState;
    if (miliState === "NOT_STARTED") {
      setShowMicrophoneSelector(true);
    }
  }, [miliState]);

  useEffect(() => {
    activeMeetingIdRef.current = activeMeetingId;
    if (activeMeetingId) {
      resetStateForNewMeeting();
    }
  }, [activeMeetingId]);

  useEffect(() => {
    if (miliState === "IN_PROGRESS") {
      requestWakeLock();
    } else {
      releaseWakeLock();
    }
  }, [miliState]);

  const handleUpdateMeetingTime: HandleUpdateMeetingMaxTiming = ({
    data,
    action,
  }) => {
    if (action === "UPDATE") {
      setMeetingMaxTime(data.remaining_time);
    }
    if (action === "RESET") {
      setMeetingMaxTime(null);
    }
  };


  const selectMicDevice = (deviceId) => {
    console.log("Called from Form - selectMicDevice", deviceId);
    userHasInteractedRef.current = true;
    setSelectedMicDeviceId(deviceId);
    localStorage.setItem(LOCAL_STORAGE_MIC_KEY, deviceId);
  }

  const selectSpeakerDevice = (deviceId) => {
    console.log("Called from Form - selectSpeakerDevice", deviceId);
    userHasInteractedRef.current = true;
    setSelectedSpeakerDeviceId(deviceId);
    localStorage.setItem(LOCAL_STORAGE_SPEAKER_KEY, deviceId);
  }



  useEffect(() => {
    selectedMicDeviceIdRef.current = selectedMicDeviceId;
    selectedSpeakerDeviceIdRef.current = selectedSpeakerDeviceId;
    if (selectedMicDeviceId && selectedSpeakerDeviceId) {
      console.log("Devices have been changed");
      const micDevice = audioInputDevices.find(device => device.Id === selectedMicDeviceId);
      const speakerDevice = audioOutputDevices.find(device => device.Id === selectedSpeakerDeviceId);
      console.log("Selected mic device:", micDevice);
      console.log("Selected speaker device:", speakerDevice);
      sendSelectedDevices(selectedMicDeviceId, selectedSpeakerDeviceId);
    }
    if (selectedMicDeviceId) {
      console.log("Selected mic device:", selectedMicDeviceId);
      const device = audioInputDevices.find(device => device.Id === selectedMicDeviceId);
      if (device) {
        Mixpanel.track(EventNames.AUDIO_DEVICE_CHANGE, {
          "Meeting ID": activeMeetingIdRef.current,
          "Device Type": "Mic",
          "Device ID": selectedMicDeviceId,
          "Device Name": device.Name,
        });
      }
    }

    if (selectedSpeakerDeviceId) {
      console.log("Selected speaker device:", selectedSpeakerDeviceId);
      const device = audioOutputDevices.find(device => device.Id === selectedSpeakerDeviceId);
      if (device) {
        Mixpanel.track(EventNames.AUDIO_DEVICE_CHANGE, {
          "Meeting ID": activeMeetingIdRef.current,
          "Device Type": "Speaker",
          "Device ID": selectedSpeakerDeviceId,
          "Device Name": device.Name,
        });
      }
    }
  }, [selectedMicDeviceId, selectedSpeakerDeviceId]);


  const sendSelectedDevices = (micDeviceId, speakerDeviceId) => {
    if (window.chrome && window.chrome.webview) {
      window.chrome.webview.postMessage({
        type: 'deviceSelection',
        micDeviceId: micDeviceId,
        speakerDeviceId: speakerDeviceId
      });
    }
    console.log("Selected mic device:", micDeviceId);
    console.log("Selected speaker device:", speakerDeviceId);
  };

  const resetStateForNewMeeting = () => {
    // Reset all relevant states and references for a new meeting
    packetSequenceNumberRef.current = 0;
    sentPacketsRef.current = {};
    lastAckIdRef.current = -1;
    reconnectAttemptsRef.current = 0;
    audioChunksRef.current = [];
    audioMetadataRef.current = null;
  };

  const connect = async (id, alerts = true, depth = 0) => {
    if (depth >= MAX_RECONNECT_DEPTH) {
      return;
    }
    if (networkStatus === "NO_NETWORK") {
      toastEmitter.show({
        message: "Please check your internet connection",
        severity: "error",
      });
      play(AUDIO_PATH.ERROR);
      return;
    }
    const permissionStatus = await navigator.permissions.query({
      name: "microphone" as PermissionName,
    });
    if (permissionStatus.state === "denied") {
      toastEmitter.show({
        message: "Please give microphone permission to continue",
        severity: "info",
      });
      play(AUDIO_PATH.ERROR);
      return;
    }

    setActiveMeetingId(id);

    if (!socketRef.current || socketRef.current.readyState === WebSocket.CLOSED) {
      const url = ROUTES.WS_SOCKET(id, token);
      socketRef.current = new WebSocket(url);


      socketRef.current.onopen = () => {
        clearTimeout(connectTimeoutRef.current);
        isConnectedRef.current = true;
        sendCommand("START");
      };

      socketRef.current.onclose = (event) => {
        console.log("WebSocket disconnected", event);

        if (networkStatusRef.current === "NO_NETWORK") return;

        if (!event.wasClean || ["IN_PROGRESS", "RESUME"].includes(miliStateRef.current)) {
          const errMsg = event.reason || "Unable to connect to server";

          if (alerts) {
            showAlert(errMsg);
          }

          Mixpanel.track(EventNames.MEETING_ALERT, {
            "Meeting ID": activeMeetingIdRef.current,
            "Alert Type": "Error",
            Category: "Socket",
            "Alert Message": errMsg,
            "Error Code": event.code,
          });

          setMiliState("RESUME");
          disconnect();

          console.log(reconnectAttemptsRef.current)
          // Improved reconnection logic
          if (!event.wasClean) {
            if (reconnectAttemptsRef.current < maxReconnectAttempts) {
              const delay = Math.min(initialReconnectDelay, (300 * 1000) - (initialReconnectDelay * reconnectAttemptsRef.current));
              console.log(
                `Attempting to reconnect in ${delay}ms (attempt ${reconnectAttemptsRef.current + 1}/${maxReconnectAttempts})`
              );
              reconnectAttemptsRef.current = reconnectAttemptsRef.current + 1;
              setMiliState("RECONNECTING");
              if (delay > 0) {
                connectTimeoutRef.current = setTimeout(() => {
                  if (!socketRef.current || socketRef.current.readyState === WebSocket.CLOSED) {
                    connect(id, alerts = reconnectAttemptsRef.current % 10 === 0);
                  }
                }, delay);
              }
            } else {
              console.log("Max reconnection attempts reached. Please try manually reconnecting.");
              toastEmitter.show({
                message: "Unable to reconnect. Please try again later.",
                severity: "error",
              });
              reconnectAttemptsRef.current = 0;
              setMiliState('STOPPED');
              stopRecording();
              play(AUDIO_PATH.ERROR);
            }

            return;
          }

          stopRecording();
        } else {
          disconnect();
          stopRecording();
        }
      };

      socketRef.current.onerror = (error: ErrorEvent) => {
        console.error("WebSocket connection error: ", error);

        const errMsg = error.message || "Socket connection error";

        if (alerts) {
          showAlert(errMsg);
        }

        Mixpanel.track(EventNames.MEETING_ALERT, {
          "Meeting ID": activeMeetingIdRef.current,
          "Alert Type": "Error",
          Category: "Socket",
          "Alert Message": errMsg,
        });

        setMiliState("RESUME");

        disconnect();
        // stopRecording();
      };

      socketRef.current.onmessage = (event) => {
        const data = JSON.parse(event.data);

        if (data.type === "TIMER") {
          handleUpdateMeetingTime({ data: data, action: "UPDATE" });
        }

        // Handle ACK from server
        if (data.type === "ACK") {
          const ackId = data.sequence_number;
          lastAckIdRef.current = ackId;

          // Remove acknowledged packets from sentPacketsRef
          for (let seq = 0; seq <= ackId; seq++) {
            delete sentPacketsRef.current[seq];
          }
        }

        // Existing code
        // Start recording on 'listening' status
        if (data.type === "STATUS" && data.status === "listening") {
          stopRecording();
          startRecording();
        }

        // Disconnect on 'stopped' status
        if (data.type === "STATUS" && (data.status === "stopped" || data.status === "stopped_via_timer" || data.status === "paused_due_to_silence")) {
          disconnect();
        }

        if (data.type === "ALERT" && data.message) {
          if (data.sub_type === "NO_AUDIO") {
            showAlert(data.message);
            setShowMicrophoneSelector(true);
            Mixpanel.track(EventNames.NO_AUDIO_INPUT_DETECTED, {
              "Meeting ID": activeMeetingIdRef.current,
            });
          }
          else if (data.sub_type === "NO_TRANSCRIPT") {
            showAlert(data.message);
            setShowMicrophoneSelector(true);
            Mixpanel.track(EventNames.NO_TRANSCRIPTION_DETECTED, {
              "Meeting ID": activeMeetingIdRef.current,
            });
          }
          else if (data.sub_type === "NO_NOTES") {
            Mixpanel.track(EventNames.NO_NOTES_DETECTED, {
              "Meeting ID": activeMeetingIdRef.current,
            });
          }
        }
      };
    } else {
      disconnect();
      setTimeout(() => connect(id, depth = depth + 1), 1500);
    }
  };

  const showAlert = (message) => {
    toastEmitter.show({
      message: message,
      severity: "error",
    });
    play(AUDIO_PATH.ERROR);
  }

  const disconnect = () => {
    if (socketRef.current) {
      socketRef.current.close();
      socketRef.current = null;
      isConnectedRef.current = false;
      isSocketReadyToSendData.current = false;
      setMeetingMaxTime(null)
      console.log("WebSocket disconnected");
    }
  };

  // STOP | PAUSE | UNPAUSE
  const pauseStop = (type) => {
    if (type === "STOP") {
      setMiliState(socketRef.current ? "FINALIZING_NOTES" : "RESUME"); // RESUME for offline case
      stopRecording();
      sendCommand("STOP");
    }
    if (socketRef.current) {
      if (type === "PAUSE") {
        setMiliState("RESUME");
        stopRecording();
      }
      if (type === "UNPAUSE") {
        startRecording();
      }
    }
  };

  async function startRecording() {
    isRecordingRef.current = true;

    // WebView handling
    if ((window as any).startRecording) {

      sendSelectedDevices(selectedMicDeviceId, selectedSpeakerDeviceId);
      setMiliState("IN_PROGRESS");
      await sleep(1000);

      if (audioMetadataRef.current) {
        socketRef.current?.send(
          JSON.stringify({
            type: "AUDIO_METADATA",
            audio_metadata: audioMetadataRef.current
          })
        );
        resendUnacknowledgedPackets();
      }
      isSocketReadyToSendData.current = true;
      (window as any).startRecording();
      return;
    }

    try {
      // await sleep(1000); // 1 second delay

      var CHANNELS = 1;
      var SAMPLE_WIDTH = 2; // bytes

      const stream = await navigator.mediaDevices.getUserMedia({
        audio: {
          channelCount: CHANNELS,
          autoGainControl: false,
          echoCancellation: false,
          noiseSuppression: false,
        },
      });


      streamRef.current = stream;

      socketRef.current?.send(
        JSON.stringify({
          type: "AUDIO_METADATA",
          audio_metadata: { sample_rate: SAMPLE_RATE, channels: CHANNELS, sample_width: SAMPLE_WIDTH },
        })
      );

      // Resend unacknowledged packets on reconnection
      resendUnacknowledgedPackets();
      isSocketReadyToSendData.current = true;


      const newAudioContext = new AudioContext({ sampleRate: SAMPLE_RATE });
      const source = newAudioContext.createMediaStreamSource(stream);
      const newScriptProcessor = newAudioContext.createScriptProcessor(
        16384,
        1,
        1
      );

      source.connect(newScriptProcessor);
      newScriptProcessor.connect(newAudioContext.destination);

      newScriptProcessor.onaudioprocess = (audioProcessingEvent) => {
        const inputBuffer = audioProcessingEvent.inputBuffer;
        const inputData = inputBuffer.getChannelData(0);

        // Convert Float32Array to Int16Array for more efficient transmission
        const pcmData = new Int16Array(inputData.length);
        for (let i = 0; i < inputData.length; i++) {
          pcmData[i] = Math.max(
            -32768,
            Math.min(32767, Math.floor(inputData[i] * 32768))
          );
        }

        const audioBase64 = arrayBufferToBase64(pcmData.buffer);

        // Assign sequence number
        const sequenceNumber = packetSequenceNumberRef.current++;
        console.log(sequenceNumber);
        // Store the packet
        sentPacketsRef.current[sequenceNumber] = audioBase64;

        // Ensure the buffer size does not exceed the maximum allowed size
        if (Object.keys(sentPacketsRef.current).length > maxPacketBufferSize) {
          const oldestSequenceNumber = Math.min(...Object.keys(sentPacketsRef.current).map(Number));
          delete sentPacketsRef.current[oldestSequenceNumber];
        }

        // Send the packet with sequence number
        if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN && isSocketReadyToSendData.current) {
          socketRef.current.send(
            JSON.stringify({ type: "AUDIO", audio_data: audioBase64, sequence_number: sequenceNumber })
          );
        }
      };

      audioContextRef.current = newAudioContext
      scriptProcessorRef.current = newScriptProcessor

      setMiliState("IN_PROGRESS");
    } catch (error) {
      console.error("Error starting recording:", error);
      setMiliState("RESUME");
      disconnect();
      if (error.message === "Permission denied") {
        toastEmitter.show({
          message: "Please give microphone permission to continue",
          severity: "info",
        });
        play(AUDIO_PATH.ERROR);
        return;
      }
      toastEmitter.show({
        message: error.message,
        severity: "error",
      });
    }
  }

  function stopRecording() {
    isRecordingRef.current = false;

    // WebView handling
    if ((window as any).stopRecording) {
      (window as any).stopRecording();
      return;
    }

    if (scriptProcessorRef.current) {
      scriptProcessorRef.current.disconnect()
      scriptProcessorRef.current = null;
      // scriptProcessor.disconnect();
      // setScriptProcessor(null);
    }
    if (audioContextRef.current) {
      audioContextRef.current.close();
      audioChunksRef.current = null
      // setAudioContext(null);
    }
    if (streamRef.current) {
      streamRef.current.getTracks().forEach((track) => track.stop());
      streamRef.current = null;
    }
  }

  function sendCommand(command) {
    console.log("Sending command:", command);
    if (!socketRef.current) return;
    console.log("Sending command 2:", command);

    socketRef.current.send(JSON.stringify({ type: "COMMAND", command: command }));
    console.log("Command sent", command)
  }

  // Function to resend unacknowledged packets on reconnection
  const resendUnacknowledgedPackets = () => {
    console.log("Resending existing ones for now which don't have any ack");
    const packets = sentPacketsRef.current;
    const keys = Object.keys(packets)
      .map(Number)
      .sort((a, b) => a - b);
    const startSequence = lastAckIdRef.current + 1;
    console.log(`Sending all things after ${startSequence}`)
    for (const seq of keys) {
      if (seq >= startSequence) {
        const packet = packets[seq];
        socketRef.current?.send(
          JSON.stringify({ type: "AUDIO", audio_data: packet, sequence_number: seq })
        );
      }
    }
  };

  useEffect(() => {
    return () => {
      if (socketRef.current) {
        setActiveMeetingId(null);
        socketRef.current.close();
      }
    };
  }, []);

  // WebView handling
  useEffect(() => {
    // Define the function and expose it to the window object
    (window as any).onNewRecording = (audioData, isMetaData: boolean = false) => {
      let audioObj;

      if (isMetaData) {
        audioObj = { type: "AUDIO_METADATA", audio_metadata: JSON.parse(audioData) };
        audioMetadataRef.current = JSON.parse(audioData);
        socketRef.current?.send(JSON.stringify(audioObj));
      } else {
        // Assign sequence number
        const sequenceNumber = packetSequenceNumberRef.current++;
        // Store the packet
        sentPacketsRef.current[sequenceNumber] = audioData;

        // Ensure the buffer size does not exceed the maximum allowed size
        if (Object.keys(sentPacketsRef.current).length > maxPacketBufferSize) {
          const oldestSequenceNumber = Math.min(...Object.keys(sentPacketsRef.current).map(Number));
          delete sentPacketsRef.current[oldestSequenceNumber];
        }

        audioObj = { type: "AUDIO", audio_data: audioData, sequence_number: sequenceNumber };
        if (isSocketReadyToSendData.current) {
          socketRef.current?.send(JSON.stringify(audioObj));
        }

      }

    };

    (window as any).onDeviceList = (devices) => {
      handleDeviceList(devices);
    }

    (window as any).OnDefaultDeviceChanged = (device) => {
      handleSystemDefaultAudioDeviceChange(device);
    }


    return () => {
      // Cleanup: remove the function from the window object when the component unmounts
      delete (window as any).onNewRecording;
      delete (window as any).onDeviceList;
      delete (window as any).OnDefaultDeviceChanged;
    };
  }, []);

  useEffect(() => {
    // Set up the message listener
    const handleMessage = (event) => {
      if (event.data && event.data.type === 'deviceList') {
        handleDeviceList(event.data.devices);
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [handleDeviceList]);

  const handleExtendMeeting = () => {
    sendCommand("EXTEND");
    setMeetingExtended(true);
    setTimeout(() => {
      setMeetingExtended(false);
    }, 5000)
  };

  return (
    <SocketContext.Provider
      value={{
        connect,
        disconnect,
        startRecording,
        sendCommand,
        isConnectedRef,
        activeMeetingId,
        pauseStop,
        miliState,
        setMiliState,
        setActiveMeetingId,
        activeMeetingIdRef,
        socketRef,
        meetingMaxTime,
        meetingExtended,
        handleExtendMeeting,
        audioInputDevices,
        audioOutputDevices,
        selectMicDevice,
        selectSpeakerDevice,
        selectedMicDeviceId,
        selectedSpeakerDeviceId,
        showMicrophoneSelector,
        setShowMicrophoneSelector
      }}
    >
      {children}
    </SocketContext.Provider>
  );
};