import {
	Box,
	CircularProgress,
	IconButton,
	Popover,
	Typography,
} from "@mui/material";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import { fetchIntegrationsList } from "../../redux/actions/appActions";
import ThirdPartyIntegrationsCard from "./ThirdPartyIntegrationsCard";
import ColorsConst from "../../utils/ColorsConst";
import M3HeadlineLarge from "../customTypography/M3HeadlineLarge";
import M3HeadlineSmall from "../customTypography/M3HeadlineSmall";
import CloseIcon from "@mui/icons-material/Close";
import Mixpanel, { EventNames } from "../../utils/analytics/mixpanel";
import { useDeviceStatus } from "../../hooks/useDeviceStatus";

const ThirdPartyIntegrations = ({ onClose, selectedName = null, onSuccess, section }) => {
	const dispatch = useDispatch<AppDispatch>();
	const integrationsList = useSelector(
		(state: RootState) => state.app.integrationsList
	);
	const { isSmallDevice } = useDeviceStatus();


	useEffect(() => {
		if (!integrationsList.list) {
			dispatch(fetchIntegrationsList());
		}
		Mixpanel.track(EventNames.MODAL_OPENED, {
			'Modal Name': "Integration Connect",
			'Modal Type': 'Form Modal',
			'Screen': window.location.pathname,
			'Section': section
		  });
	}, []);

	return (
		<Popover
			onClose={onClose}
			open={true}
			sx={{
				".MuiBackdrop-root": {
					background: "rgba(0,0,0,0.75)",
				},
				".MuiPaper-root": {
					width: isSmallDevice ? "calc(100% - 16px)" : "70%",
					left: "50% !important",
					top: "50% !important",
					transform: "translate(-50%, -50%) !important",
					background: "transparent",
					maxWidth: "860px",
					borderRadius: "16px",
					boxSizing: "border-box",
				},
			}}
		>
			<IconButton
				sx={{ position: "absolute", right: "16px", top: "16px" }}
				onClick={onClose}
				size="small"
			>
				<CloseIcon />
			</IconButton>
			{integrationsList.isLoading ? (
				<Box
					sx={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						width: "100%",
						padding: "36px",
						boxSizing: "border-box",
						flexDirection: "column",
						height: isSmallDevice
							? "calc(100vh - 40px)"
							: "calc(100vh - 120px)",
						bgcolor: ColorsConst.DARK_SURFACE_CONTAINER,
					}}
				>
					<CircularProgress
						sx={{ color: ColorsConst.DARK_PRIMARY, mb: 2 }}
						size={40}
					/>
					<Typography
						style={{
							fontWeight: 500,
							color: ColorsConst.DARK_PRIMARY,
							fontSize: "18px",
						}}
					>
						Loading Integrations
					</Typography>
				</Box>
			) : (
				<Box
					p={isSmallDevice ? "20px 16px 4px" : "24px 40px 8px"}
					borderRadius={"16px"}
					sx={{
						bgcolor: ColorsConst.DARK_SURFACE_CONTAINER,
						maxHeight: isSmallDevice
							? "calc(100vh - 64px)"
							: "calc(100vh - 120px)",
						overflowY: "auto",
					}}
				>
					<M3HeadlineLarge mb={isSmallDevice ? "24px" : "32px"}>
						Integrations
					</M3HeadlineLarge>
					{integrationsList.list?.map((data) => {
						if (window.isIOS && data.header === "Calendar") return null;
						return (
							<Box mb={isSmallDevice ? "24px" : "28px"}>
								<M3HeadlineSmall mb={isSmallDevice ? "20px" : "24px"}>
									{data.header}
								</M3HeadlineSmall>
								{data.integrations.map((integrationCardData) => (
									<ThirdPartyIntegrationsCard
										section={section}
										onSuccess={onSuccess}
										selectedName={selectedName}
										{...integrationCardData}
									/>
								))}
							</Box>
						);
					})}
				</Box>
			)}
		</Popover>
	);
};

export default ThirdPartyIntegrations;
