import ReactDOM from "react-dom/client";
import App from "./App";
import * as Sentry from "@sentry/react";
import { PostHogProvider } from 'posthog-js/react';
import Mixpanel from './utils/analytics/mixpanel';
import initWebVitals from './utils/analytics/initWebVitals';
import ErrorFallback from "./components/errorFallback";
import { preloadAssets } from "./iconPreloader";

import './global.styled.css';
import { REACT_APP_PUBLIC_POSTHOG_HOST, REACT_APP_PUBLIC_POSTHOG_KEY } from "./api/config";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import ColorsConst from "./utils/ColorsConst";

preloadAssets();

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DNS,
  integrations: [
    Sentry.browserTracingIntegration(),
    // Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  // replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  // replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  environment: process.env.REACT_APP_SENTRY_ENV
});

Mixpanel.init();

const rootElement = document.createElement("div");
rootElement.id = "react-chrome-app";
rootElement.style.height = "100%";
document.body.appendChild(rootElement);

const root = ReactDOM.createRoot(rootElement);
const { palette } = createTheme();
const theme = createTheme({
  palette: {
    checked: palette.augmentColor({ color: { main: ColorsConst.DARK_TERTIARY } }),
  },
});

root.render(
  <Sentry.ErrorBoundary fallback={<ErrorFallback error='' />}>
    <PostHogProvider
      apiKey={REACT_APP_PUBLIC_POSTHOG_KEY}
      options={{ api_host: REACT_APP_PUBLIC_POSTHOG_HOST }}
    >
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </PostHogProvider>
  </Sentry.ErrorBoundary>
);


// Initialize Web Vitals collection
initWebVitals();
