import axios, { AxiosRequestConfig } from "axios";
import { API_BASE_URL, ROUTES } from "./config";
import { selectToken, setToken } from "../redux/reducers/appSlice";
import store from "../redux/store";
import packageJson from "../../package.json";
import { compareVersions } from "../utils/Util";

const refreshToken = async () => {
  try {
    const refresh_token = localStorage.getItem("refresh_token");
    const res = await post(ROUTES.REFRESH_TOKEN, { refresh_token });
    return res;
  } catch (err) {
    console.log(err);
  }
};

// Create Axios instance with default configuration
const instance = axios.create({
  baseURL: API_BASE_URL, // Set your base URL here
  timeout: 120000, // Set request timeout - 2min
  headers: {
    "Content-Type": "application/json",
    // You can add default headers here
  },
});

// Define methods for making common API requests
const makeRequest = async <T>(
  method: AxiosRequestConfig["method"],
  url: string,
  data: any = null,
  params: any = null,
  headers: any = null,
  extraParams: any = {}
): Promise<T> => {
  try {
    const token = selectToken(store.getState());
    const response = await instance.request<T>({
      method,
      url,
      data,
      params,
      headers: {
        ...headers,
        "X-Timezone": Intl.DateTimeFormat().resolvedOptions().timeZone,
        Authorization: token ? `Bearer ${token}` : "", // Include the token in the Authorization header if it exists
      },
      ...extraParams,
    });

    // Check X-App-Version header from the response
    const xAppVersion = response.headers["x-app-version"];
    const jsVersion = packageJson.version;

    // Compare it with the current app version to determine if an update is needed
    if (xAppVersion && compareVersions(jsVersion, xAppVersion) === -1) {
      localStorage.removeItem("last-version-check");
    }

    return response.data;
  } catch (error) {
    if (
      error.response &&
      error.response.status === 401 &&
      !error.config._retry
    ) {
      try {
        const res: any = await refreshToken();
        store.dispatch(setToken(res?.id_token)); // Update token in the store
        error.config.headers["Authorization"] = `Bearer ${res?.id_token}`;
        error.config._retry = true; // mark it so it doesn't get processed again
        const retryResponse = await instance.request<T>(error.config);
        return retryResponse.data;
      } catch (retryError) {
        throw retryError.response.data;
      }
    }
    throw error.response.data;
  }
};

export const get = async <T>(
  url: string,
  params?: any,
  headers?: any
): Promise<T> => {
  return makeRequest<T>("GET", url, null, params, headers);
};

export const post = async <T>(
  url: string,
  data?: any,
  headers?: any,
  extraParams?: any
): Promise<T> => {
  return makeRequest<T>("POST", url, data, null, headers, extraParams);
};

export const put = async <T>(
  url: string,
  data?: any,
  headers?: any
): Promise<T> => {
  return makeRequest<T>("PUT", url, data, null, headers);
};

export const del = async <T>(url: string, headers?: any): Promise<T> => {
  return makeRequest<T>("DELETE", url, null, null, headers);
};

// Add more methods for other HTTP methods as needed

export default instance;
